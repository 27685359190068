
import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch,
} from "vue-property-decorator";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
@Component({
  props: {},
  components: {Breadcrumbs},
})
export default class PersonalDetails extends Vue {
  zzlApi: any;
  condition: any = { idNumber: "" };
  tableData:any = []
  async getData () {
    if (!this.condition.idNumber) return this.$message.warning('请输入证件号码!')
    let res = await this.zzlApi.home.GetTestDetail(this.condition);
    if (res.code === 0) {
      this.tableData = res.data
    } else {
      this.$message.error(res.msg)
    }
  }
  async save (row:any) {
    let res = await this.zzlApi.home.UpdateTest({
      id: row.id,
      willTestCount: row.willTestCount
    });
    if (res.code === 0) {
      this.$message.success('保存成功!')
    } else {
      this.$message.error(res.msg)
    }
  }
  timeBlur (e:any) {
    if (e.target.value.trim().length === 0) {
      e.target.value = '0'
    }
  }
  resetCondition () {
    this.condition.idNumber = ''
  }

  @Watch("$store.state.stateStatus")
  async initData() {
  }
  mounted() {
    this.initData();
  }
}
